<template>
	<div>
		<div class="flex justify-start">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="商品名称">
					<el-input v-model="searchForm.name" placeholder="商品名称"></el-input>
				</el-form-item>
				<el-form-item label="商品分类">
					<el-select v-model="classifyIndex" placeholder="全部" clearable @change="search()">
						<el-option v-for="(item,index) in classify" :key="index" :label="item.name" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="name" label="商品名称" width="280">
				<template slot-scope="scope">
					<div class="flex align-center">
						<el-image v-if="scope.row.cover" class="cover" :src="scope.row.cover" fit="cover"></el-image>
						{{scope.row.name}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="classify_name" label="商品分类" width="180">
			</el-table-column>
			<el-table-column prop="price" label="商品价格">
				<template slot-scope="scope">
					<div v-for="item in scope.row.specs" :key="item.id">
						{{item.sku_values}}：
						￥{{item.current_price}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="sales_commission" label="销售提成" width="180">
				<template slot-scope="scope">
					<div>
						{{scope.row.sales_commission|toFixed2}}
						<el-button icon="el-icon-edit" circle size="mini" @click="showEdit(scope.row)"></el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="是否上架" width="100">
				<template slot-scope="scope">
					<el-switch :value="scope.row.status" :active-value="1" :inactive-value="0" @change="publish(scope.row)"></el-switch>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="pageSizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
		<el-dialog title="销售提成" :visible.sync="editDialog.visible" append-to-body>
			<el-form :model="editDialog.form" @submit.native.prevent>
				<el-form-item label="名称">
					{{editDialog.form.name}}
				</el-form-item>
				<el-form-item label="规格">
					<span class="margin-right" v-for="item in editDialog.form.specs" :key="item.id">
						{{item.sku_values}}：
						￥{{item.current_price}}
					</span>
				</el-form-item>
				<el-form-item label="销售提成">
					<el-input-number v-model="editDialog.form.sales_commission" :min="0" label="销售提成" :precision="2" @keydown.native.enter="editSubmit()"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editDialog.visible = false">取 消</el-button>
				<el-button type="primary" @click="editSubmit()" :loading="editDialog.loading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				classify: [], // 分类选项
				classifyIndex: '', // 选择的分类index
				pageSizes: [10, 20, 30, 50],
				searchForm: {
					name: undefined, // 否 string 产品名称
					classify: undefined, // 否 array 分类ID
					status: undefined, // 否 int 状态
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
					total: 0,
				},
				editDialog: {
					loading: false,
					visible: false,
					form: {}
				}
			};
		},
		mounted() {
			// 获取商品分类
			this.$api.classify.getBrandClassify(this.$api.classify.GOODS).then(res => {
				this.classify = res.data
			})
			// 查询数据
			this.search()
		},
		methods: {
			editSubmit() {
				// 校验价格
				let priceArr = this.editDialog.form.specs.map(item=>{
					return Number(item.current_price)
				})
				let minPrice = Math.min(...priceArr)
				if(this.editDialog.form.sales_commission > minPrice){
					this.$message.warning('销售提成不能大于商品价格')
					return;
				}
				// 提交数据
				this.editDialog.loading = true;
				this.$api.goods.saveSaleCommission({
					id: this.editDialog.form.id,
					sales_commission: this.editDialog.form.sales_commission
				}).then(res => {
					this.editDialog.loading = false;
					if (res.code == 200) {
						this.$message.success('保存成功')
						this.editDialog.visible = false;
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			showEdit(row) {
				this.editDialog.form = row;
				this.editDialog.visible = true;
			},
			search() {
				this.loading = true;
				// 将对象空字符串转为undefined
				let searchForm = this.$util.emptyToUndefined(this.searchForm);
				// 分类条件，传给后台子分类数组，如果该分类没有子分类，则传自己
				if (this.classifyIndex !== '') {
					if (this.classify[this.classifyIndex].children.length) {
						searchForm.classify = this.classify[this.classifyIndex].children.map(item => {
							return item.id
						})
					} else {
						searchForm.classify = [this.classify[this.classifyIndex].id]
					}
				}
				this.$api.goods.getBrandGoods(searchForm).then(res => {
					this.searchForm.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.goods.setBrandStatus({
					id: item.id
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
