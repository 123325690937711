<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="商品名称">
					<el-input v-model="searchForm.name" placeholder="商品名称"></el-input>
				</el-form-item>
				<el-form-item label="商品分类">
					<el-select v-model="searchForm.classify" placeholder="全部" clearable @change="search()">
						<el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="searchForm.status" placeholder="全部" clearable @change="search()">
						<el-option label="上架" :value="1"></el-option>
						<el-option label="下架" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button icon="el-icon-plus" @click="toEdit()">新增商品</el-button>
					<!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="name" label="商品名称" width="280">
				<template slot-scope="scope">
					<div class="flex align-center">
						<el-image v-if="scope.row.cover" class="cover" :src="scope.row.cover" fit="cover"></el-image>
						{{scope.row.name}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="classify_name" label="商品分类" width="180">
			</el-table-column>
			<el-table-column prop="current_price" label="商品价格" width="180">
			</el-table-column>
			<el-table-column prop="status" label="是否上架" width="100">
				<template slot-scope="scope">
					<el-switch :value="scope.row.status" :active-value="1" :inactive-value="0" @change="publish(scope.row)"></el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="updated_at" label="最后修改时间">
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="150">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				pageType: this.$api.classify.GOODS,
				loading: false, // 表格加载状态
				tableData: [], // 数据
				classify: [], // 分类选项
				searchForm: {
					name: undefined, // 否 string 产品名称
					classify: undefined, // 否 int 分类ID
					status: undefined, // 否 int 状态
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			// 获取商品分类
			this.$api.classify.getClassify(this.pageType).then(res => {
				this.classify = res.data
			})
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				// 将对象空字符串转为undefined
				let searchForm = this.$util.emptyToUndefined(this.searchForm);
				this.$api.goods.getGoods(searchForm).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toEdit(item) {
				if (item) {
					this.$router.push(`/goods/edit/${item.id}`)
				} else {
					this.$router.push(`/goods/edit`)
				}
			},
			del(item) {
				const loading = this.$loading()
				this.$api.goods.del({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.search()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.goods.save({
					id: item.id,
					status: item.status
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
